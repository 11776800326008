.App {
  height: 100vh;
  background-color: #282c34;
}

.start_page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main_button {
  border: none;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  font-size: 60px;
}

.users_icons {
  margin-left: 15px;
  margin-right: 15px;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  font-size: 25px;

  display: inline-block;
}

.users_status_icons {
  max-width: 280px;
  max-height: 50px;
  margin: auto;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);


  overflow: auto;
  white-space: nowrap;
}

h1 {
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.time_text {
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 20px;

}

.user_button {
  font-size: 30px;
  width: 100%;
  height: 90px;
  border: none;
  border-radius: 30px;
}

.users_list {
  height: 55vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.users_page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.add_user_page {
  width: 70%;
}

.Name_Text_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.add_user_button {
  font-size: 30px;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 30px;
}